<template>
  <div>
    <b-card title="Jenis Ujian">
      <b-list-group horizontal="md">
        <b-list-group-item>
          <span class="mr-2">
            <b>Nama jenis ujian :</b>
          </span>
          <span>{{ data_jenis_ujian.nama_jenis_ujian }}</span>
        </b-list-group-item>
        <b-list-group-item>
          <span class="mr-2">
            <b>Kelas :</b>
          </span>
          <span>{{ data_jenis_ujian.kelas.nama_kelas }}</span>
        </b-list-group-item>
        <b-list-group-item>
          <span class="mr-2">
            <b>Status :</b>
          </span>
          <b-badge variant="success" v-if="data_jenis_ujian.status=1">Aktif</b-badge>
          <b-badge variant="danger" v-else>Tidak Aktif</b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card title="Tambah Ujian ➕">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <b-form-group
              label="Nama Ujian"
              label-for="v-nama-ujian"
            >
              <b-form-input
                id="v-nama-ujian"
                v-model="data.nama_ujian"
                placeholder="Nama Ujian"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mata Pelajaran"
              label-for="v-id-mata-pelajaran"
            >
            <b-form-select
              v-model="data.id_mapel"
              :options="optionMataPelajaran"
            />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Jumlah Soal"
              label-for="v-jumlah-soal"
            >
              <b-form-input
                id="v-jumlah-soal"
                v-model="data.jumlah_soal"
                placeholder="Jumlah Soal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Waktu Ujian (menit)"
              label-for="v-waktu-ujian"
            >
              <b-form-input
                id="v-waktu-ujian"
                v-model="data.waktu"
                placeholder="Waktu Ujian (menit)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Passing Grade"
              label-for="v-pg"
            >
              <b-form-input
                id="v-pg"
                v-model="data.pg"
                placeholder="Passing Grade"
              />
            </b-form-group>
          </b-col>
          <b-col>
              <b-form-group
                  label="Cara Pengerjaan"
                  label-for="v-cara-pengerjaan"
              >
              <quill-editor
                  id="cara_pengerjaan"
                  rows="4"
                  v-model="data.cara_pengerjaan"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-for="v-status"
            >
              <b-form-select
              v-model="data.status"
              :options="options_status"
            />
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="saveData()"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormSelect, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'

const Block = Quill.import('blots/block')
Block.tagName = 'span'
Quill.register(Block)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BBadge,
    BListGroup,
    BListGroupItem,
    quillEditor,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        nama_ujian: '',
        id_kelas: '',
        id_mapel: '',
        id_jenis_ujian: '',
        jumlah_soal: '',
        waktu: '',
        pg: '',
        cara_pengerjaan: '',
        status: '',
      },
      data_jenis_ujian: [],
      options_status: [
        { value: '1', text: 'Aktif' },
        { value: '0', text: 'Tidak Aktif' },
      ],
      optionMataPelajaran: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(`jenis-ujian/${this.$route.params.id_jenis_ujian}`).then(response => {
        if (response.data.status) {
          this.data.id_kelas = response.data.data.id_kelas
          this.data.id_jenis_ujian = response.data.data.id
          this.data_jenis_ujian = response.data.data
        }
      })
      this.$http.get('mapel').then(response => {
        if (response.data.status) {
          response.data.data.forEach(element => {
            const data = {
              value: element.id,
              text: `${element.nama_mapel} (${element.jenjang})`,
            }
            this.optionMataPelajaran.push(data)
          })
        }
      })
    },
    saveData() {
      const data = {
        nama_ujian: this.data.nama_ujian,
        id_kelas: this.data.id_kelas,
        id_mapel: this.data.id_mapel,
        id_jenis_ujian: this.data.id_jenis_ujian,
        jumlah_soal: this.data.jumlah_soal,
        waktu: this.data.waktu,
        pg: this.data.pg,
        cara_pengerjaan: this.data.cara_pengerjaan,
        status: this.data.status,
      }
      this.$http.post('/ujian', data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'ujian-index', params: { id_jenis_ujian: data.id_jenis_ujian } })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
